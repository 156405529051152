import { logo512 } from "@/utils/images";
import React from "react";

type Props = {
  data: {
    title: string;
    description: string;
    keywords: string[];
    canonicalUrl: string;
  };
  host?: string | null;
};

const SeoMetaData = ({ data }: Props) => {
  return (
    <>
      <meta name="description" content={data.description} />
      <meta name="keywords" content={data.keywords?.join(", ")} />
      <meta
        name="robots"
        content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      />
      <link rel="canonical" href={data.canonicalUrl} />
      <link
        rel="sitemap"
        type="application/xml"
        href="https://www.shipvista.com/sitemap.xml"
      />

      <meta name="twitter:card" content={data.title} />
      <meta name="twitter:title" content={data.title} />
      <meta name="twitter:image" content={logo512} />
      <meta name="twitter:creator" content="@shipvista" />
      <meta name="twitter:site" content="@shipvista" />
      <meta name="twitter:description" content={data.description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.shipvista.com" />
      <meta property="og:image" content={logo512} />
      <meta property="og:title" content={data.title} />
      <meta property="og:description" content={data.description} />
    </>
  );
};

export default SeoMetaData;
